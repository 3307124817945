<template>
 <div>
  <layout-header />
  <router-view />
  <footer-nav />
 </div>
</template>
 
<script>
import layoutHeader from './components/header.vue'
import footerNav from '@/components/footer-nav'
export default {
  components: { 
    layoutHeader, 
    footerNav 
  }
}
</script>
 
<style lang="scss" scoped>
</style>