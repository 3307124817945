<template>
  <div>
    <div class="qsy_nav">
      <div class="qsy_nav_wrap">
        <div class="qsy_logo">
          <img
            class="qsy_img"
            src="@/assets/images/homeImg/logoHeader.png"
            alt=""
          />
        </div>
        <div class="qsy_nav_content">
          <div
            class="qsy_nav_item"
            @click="menuClick(item.meta.key)"
            :class="{
              qsy_nav_item_active: current === item.meta.key ? true : false,
              qsy_nav_item_user: item.meta.key === 'user' ? true : false,
            }"
            v-for="(item, index) in routes"
            :key="index"
          >
            <div v-if="item.meta.key === 'user'" class="qsy_user_box">
              <div class="qsy_user_haed">
                <img
                  class="qsy_img"
                  src="@/assets/images/homeImg/headPic.png"
                  alt=""
                />
              </div>
              <div class="qsy_user_name">{{ userName ? userName : '用户名' }}</div>
              <div class="qsy_user_ic">
                <img
                  class="qsy_img"
                  src="@/assets/images/homeImg/down.png"
                  alt=""
                />
              </div>

              <div class="qsy_user_list">
                <div
                  class="qsy_user_list_item"
                  v-for="(ele, key) in userArr"
                  :key="key"
                  @click.stop="toUser(ele)"
                >
                  <div class="user_hoverImg">
                    <img class="qsy_img" v-if="ele.meta.icon == 'user' || ele.meta.icon == 'book' || ele.meta.icon == 'logout'" :src="ele.meta.icon == 'user' ? imgs.mime : ele.meta.icon == 'book' ? imgs.zhengshu : imgs.out " alt="">
                    <a-icon v-else :type="ele.meta.icon" />
                  </div>
                  <div class="user_hoverImged">
                    <img class="qsy_img" v-if="ele.meta.icon == 'user' || ele.meta.icon == 'book' || ele.meta.icon == 'logout'" :src="ele.meta.icon == 'user' ? imgs.mimeActive : ele.meta.icon == 'book' ? imgs.zhengshuActive : imgs.outActive " alt="">
                    <a-icon v-else :type="ele.meta.icon" />
                  </div>
                  {{ ele.meta.title }}
                </div>
              </div>
            </div>
            <div v-else class="qsy_nav_text">
              {{ item.meta.title }}
              <div class="qsy_nav_line" v-if="current === item.meta.key">
                <img
                  class="qsy_img"
                  src="@/assets/images/homeImg/line.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
import Vue from "vue";
import { getMessageList } from "@/api/news.js";
// import { getTaskData } from "@/api";
import {
  USER_NAME,
  USER_AVATAR,
  ACCESS_TOKEN,
  NEWCOUNT,
} from "@/store/mutation-types.js";
import { getFileAccessHttpUrl } from "@/utils/tools";
// import { getList } from "@/api/news.js";

export default {
  data() {
    return {
      routes: [],
      current: "",
      userArr: [],
      learnArr: [],
      data: [],
      count: null,
      token: null,
      userName: null,
      avatar: null,
      imgs:{
        mime:require('@/assets/images/homeImg/mine.png'),
        mimeActive:require('@/assets/images/homeImg/mine_active.png'),
        zhengshu:require('@/assets/images/homeImg/zhengshu.png'),
        zhengshuActive:require('@/assets/images/homeImg/zhengshu_active.png'),
        out:require('@/assets/images/homeImg/out.png'),
        outActive:require('@/assets/images/homeImg/out_active.png'),
      }
    };
  },
  computed: {
    getFileAccessHttpUrl() {
      return (avatar) => {
        return getFileAccessHttpUrl(avatar);
      };
    },
  },
  mounted() {
    if (this.token) {
      this.getMessage();
      this.userName = Vue.ls.get(USER_NAME);
      this.avatar = Vue.ls.get(USER_AVATAR);
    }
  },
  created() {
    // 初始化路由
    this.token = Vue.ls.get(ACCESS_TOKEN);
    this.current = this.$route.meta.module;
    let arr = this.$router.options.routes;
    console.log("this.$store.state.user.userIdentity", localStorage.getItem('userIdentity'));
    let userIdentity = localStorage.getItem('userIdentity');
    if (userIdentity !== '2') {
      arr = arr.filter((item) => {
        return item.path != "/talents";
      });
    }
    arr.forEach((item) => {
      if (item.meta.isMenu) {
        this.routes.push(item);
      }
      if (item.meta.key === "user") {
        
        
        this.userArr = item.children.slice(1).filter((item) => {
          if (!item.meta.hideSide) return item;
        });
      }
      // if (item.meta.key === "learn") {
      //   item.children.forEach(ele => {
      //     if (
      //       ele.name === "recommend" ||
      //       ele.name === "classroom" ||
      //       ele.name === "external" ||
      //       ele.name === "offline" ||
      //       ele.name === "cloundTalk"
      //     ) {
      //       this.learnArr.push(ele);
      //     }
      //   });
      //   // let obj = {
      //   //   name: "toolkit",
      //   //   path: "/community/tool-kit",
      //   //   meta: {
      //   //     title: "工具包",
      //   //     icon: "tool",
      //   //     module: "learn",
      //   //     moduleText: "学习"
      //   //   }
      //   // };
      //   // this.learnArr.push(obj);
      // }
    });
    this.userArr = this.userArr.filter(function(item) {
          return item.name !== "creditDetail"
        });
        console.log('学分',this.userArr)
    this.userArr.push({
      name: "exit",
      meta: {
        hideSid: false,
        icon: "logout",
        title: "退出登录",
      },
    });
    this.userArr.unshift({
      name: "user",
      meta: {
        hideSid: false,
        icon: "user",
        title: "个人中心",
      },
    })
    // this.getData();
  },

  methods: {
    menuClick(key) {
      if (key === this.$route.name) return;
      
      // if(key == 'learn'){
      //   getTaskData({
      //     taskId: Vue.ls.get("TASK_ID"),
      //     taskPathId: Vue.ls.get("TASK_PATH_ID"),
      //   }).then((res) => {
      //     if(res.code == 200 && res.result != null){
      //       let learnData = res.result;
      //       let ele = learnData.learningTaskLevelProgressList[0].learningTaskSubTaskProgressList[0]
      //       let taskLevelId = learnData.learningTaskLevelProgressList[0].taskLevelId
      //       this.current = key;
      //       this.$router.push({
      //         name: "learn",
      //         query: {
      //           id: ele.levelSubTaskId,
      //           course: ele.levelSubTaskContentId,
      //           taskLevelId: taskLevelId
      //         }
      //       });
      //     }else{
      //       this.$router.push({ name: 'tips' });
      //     }
      //   });
      // }else{
        this.current = key;
        this.$router.push({ name: key });
      // }
    },
    getMessage() {
      getMessageList().then((res) => {
        this.count = res.result;
        Vue.ls.set(NEWCOUNT, res.result);
        // NEWCOUNT
      });
    },
    toHome() {
      if (this.$route.name === "home") return;
      this.$router.push({ name: "home" });
    },
    toUser(item) {
      if (this.$route.name === item.name) return;
      if (item.name === "exit") {
        this.$store.dispatch("user/Logout").then(() => {
          this.$router.push({
            name: "login",
          });
        });
      } else {
        this.$router.push({
          name: item.name,
        });
      }
    },
    toLearn(item) {
      if (this.$route.name === item.name) return;
      this.$router.push({
        name: item.name,
      });
    },
  },
  watch: {
    $route() {
      this.current = this.$route.meta.module;
      this.getMessage();
      if (!this.userName && !this.avatar) {
        this.userName = Vue.ls.get(USER_NAME);
        this.avatar = Vue.ls.get(USER_AVATAR);
      }
    },
  },
};
</script>
 
<style lang = "scss" scoped>
.qsy_nav {
  width: 100%;
  height: 80px;
  background: #ffffff;
  .qsy_nav_wrap {
    width: 1200px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .qsy_logo {
      width: 197px;
      height: 36px;
    }
    .qsy_nav_content {
      flex: 1;
      margin-left: 60px;
      display: flex;
      justify-content: flex-end;
      height: 80px;
      align-items: center;

      .qsy_nav_item {
        margin-right: 112px;
        padding: 0 5px;
        font-size: 16px;
        font-weight: bold;
        color: #696969;
        height: 100%;
        display: flex;
        align-items: center;
        &:hover{
          color: #5db4ff;
        }
      }
      .qsy_nav_item_user{
        margin-right: 0;
      }
      .qsy_nav_item_active {
        color: #5db4ff;
      }
      .qsy_user_box {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        height: 100%;
        &:hover .qsy_user_list {
          display: block;
        }
        .qsy_user_haed {
          width: 48px;
          height: 48px;
        }
        .qsy_user_name {
          font-size: 16px;
          font-weight: bold;
          color: #696969;
          margin: 0 7px 0 14px;
        }
        .qsy_user_ic {
          width: 10px;
          height: 4px;
        }
        .qsy_user_list {
          width: 140px;
          height: 158px;
          background: #EEEEEE;
          box-shadow: 0px 0px 14px 0px rgba(187, 187, 187, 0.48);
          position: absolute;
          top: 80px;
          left: 0;
          transition: all 0.6s;
          display: none;
          z-index: 99;
        }
        .qsy_user_list_item {
          width: 100%;
          line-height: 52px;
          text-align: left;
          padding: 0 22px 0 33px;
          font-size: 14px;
          color: #989898;
          transition: all 0.2s;
          display: flex;
          align-items: center;
          .user_hoverImg{
            display: block;
            width: 14px;
            height: 14px;
            margin-right: 8px;
          }
          .user_hoverImged{
            display: none;
            width: 14px;
            height: 14px;
            margin-right: 8px;
          }
          &:last-child {
            border-bottom: none;
          }
          &:hover {
            color: #5db4ff;
            background: #ffffff;
          }
          &:hover .user_hoverImg{
            display: none;
          }
          &:hover .user_hoverImged{
            display: block;
          }
        }
      }
      .qsy_nav_text {
        height: 100%;
        position: relative;
        line-height: 80px;
        cursor: pointer;
        .qsy_nav_line {
          width: 42px;
          height: 3px;
          position: absolute;
          bottom: 21px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
.qsy_img {
  width: 100%;
  height: 100%;
  display: block;
}
</style>